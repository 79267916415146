import React from 'react';
import { connect } from 'react-redux';
import { get } from '../../utilities/common';
import { CollapsibleListColumns } from 'reaxl-molecules';
import { sendClick } from 'reaxl-analytics';
import { dealerCrawlPathDuck } from '../../ducks';

const DealerCrawlPathLocationContainer = ({
    items,
}) => (
    <CollapsibleListColumns
        title="Car Dealers by City"
        items={items}
        render={({ label, ...rest }) => (
            <a
                className="text-link link-undecorated"
                onClick={(event) => sendClick('sitemapFilterClick', event, { sitemapContent: event.target.innerText })}
                {...rest}
            >
                {label}
            </a>
        )}
        xs={2}
        md={4}
        lg={5}
    />
);

function mapStateToProps(state) {
    const crawlPathData = dealerCrawlPathDuck.selectors.getDuckState(state);
    return {
        items: get(crawlPathData, 'locations', []),
    };
}

export default connect(mapStateToProps)(React.memo(DealerCrawlPathLocationContainer));
