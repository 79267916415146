import { pageNames } from '@atc/bonnet-paths';
import { mapDispatchToActionProp } from '@atc/modular-redux';
import { useDevice } from '@atc/react-device';
import styled from '@emotion/styled';
import { hasDocumentSelection } from 'atc-js';
import { get as _get } from '../../utilities/common';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { memo, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { connect, useSelector, useStore } from 'react-redux';
import { DelayedImpressionObserver, sendImpressions, sendClick } from 'reaxl-analytics';
import { useBrand } from 'reaxl-brand';
import { useOptimizelyTrack } from 'reaxl-optimizely';
import { AutoDealer as DealerSchema } from 'reaxl-schema';

import { rebuildFindADealerPage } from '../../actions';
import LazyComponent from '../../components/LazyComponent';
import { ddpClickType, dealerRatingPageType, kbbBrand, defaultRenderItemsNumber } from '../../constants';
import {
    birfDuck,
    currentPageNameDuck,
    dealerFiltersDuck,
    dealerPaginationDuck,
    dealerResultsDuck,
    dealerUrlsDuck,
    inventoryActiveOwnerDuck,
    retainOwnerDuck,
    userDuck,
} from '../../ducks';
import { numRecords } from '../../reference/options';
import getDataIsland from '../../services/dataisland';
import { createDdpLink, createDdpScheduleLink, createLinkForGetAPriceQuote, getPageScrollPercentage } from '../../utilities';
import getResultCountText from '../../utilities/getResultCountText';
import { DealerCardContainer } from './dealercard/DealerCardContainer';
import DealerCardPlaceHolder from './dealercard/DealerCardPlaceHolder';

const NoResult = dynamic(() => import('./dealersearchresults/NoResult'));
const DealerLot = dynamic(() => import('../../components/DealerLot'));
const ExpiredDealerAlert = dynamic(() => import('./dealersearchresults/ExpiredDealerAlert'));
const DealerRatingsOverlay = dynamic(() => import('./dealercard/Modals/DealerRatingsOverlay'), { ssr: false });

const isShowVisitSite = (dealerWebsiteUrl) => (!!(dealerWebsiteUrl && dealerWebsiteUrl !== 'undefined' && dealerWebsiteUrl !== 'null' && dealerWebsiteUrl.length > 0));

const { publicRuntimeConfig } = getConfig();
const { elotPosition, kbbRatingOverlayUrl, kbbRatingBadgeUrl, elotLibraryUrl } = publicRuntimeConfig; // pass in desired elot position (6th position) here

const DealerListResultCount = styled.div`
    min-height: 20px;
    min-width: 100px;
`;

const DealerSearchResultsContainer = memo(({
    actions,
    results,
    numRecordsData,
    currentPage,
    resultCount,
    redirectExpiredPage,
    hasResults,
    cityZip,
    pageName,
    filterValues,
    isLoading,
}) => {
    const store = useStore();
    const router = useRouter();
    const { isBrand } = useBrand();
    const isKbb = isBrand(kbbBrand);
    const device = useDevice();
    const isMobile = device.type !== 'desktop';
    const optimizelyTrack = useOptimizelyTrack();
    const pageInstance = useSelector((state) => _get(state, 'birf.pageData.page.BIRF.pg_inst'));
    const [scrollRange25, setScrollRange25] = useState('');
    const [scrollRange50, setScrollRange50] = useState('');
    const [scrollRange75, setScrollRange75] = useState('');
    const scrollPercent = getPageScrollPercentage();
    const [isRatingsOpen, toggleIsRatingsOpen] = useState(false);
    const [dealerSvocid, setDealerSvocid] = useState(0);
    const ratingsIframeUrl = `${kbbRatingOverlayUrl}&svocId=${dealerSvocid}`;
    const dealerListCountText = useMemo(() => getResultCountText(resultCount), [resultCount]);

    const [visibleSkeletons, setVisibleSkeletons] = useState(defaultRenderItemsNumber);
    const [visibleItems, setVisibleItems] = useState(defaultRenderItemsNumber);
    const loadingRef = useRef(null);
    const resultsRef = useRef(results);

    // Reset state when results change
    useEffect(() => {
        if (JSON.stringify(results) !== JSON.stringify(resultsRef.current)) {
            const actualVisibleItems = results?.length >= defaultRenderItemsNumber ? defaultRenderItemsNumber : results?.length;
            setVisibleItems(defaultRenderItemsNumber);
            setVisibleSkeletons(actualVisibleItems);
            resultsRef.current = results;
        }

    }, [results]);

    // for some reason paginationDuckCreator from atc-ui change default value from 25 to 24 lead to wrong value for our page
    // so we need reset the default value here
    numRecordsData.value = numRecordsData.value === 24 ? 25 : numRecordsData.value;
    // When pageInstance changes (initial render is from undefined to a defined instance)

    const iframeMessageHandler = useCallback((event) => {
        const messageData = event.data || {};
        if (messageData.eventType === 'click') {
            setDealerSvocid(messageData.dealerId);
            toggleIsRatingsOpen(true);
            document.body.style.overflow = 'hidden';
        }
    }, [setDealerSvocid, toggleIsRatingsOpen]);

    useEffect(() => {
        // listen to click event on dealer ratings
        window.addEventListener('message', iframeMessageHandler);
    }, [pageInstance, iframeMessageHandler]);

    useEffect(() => {
        sendImpressions({
            name: 'ownerImpressions',
            data: {
                cmpPrefix: 'c_lstg_',
                pixallData: {
                    eventSource: 'dealership',
                    isCarousel: true,
                    label: 'dl_v_lstg',
                    listingType: 'lstg',
                    searchFacetZipCode: filterValues.zip,
                },
            },
            resultsDuck: dealerResultsDuck,
        });
    }, [filterValues.zip]);

    useEffect(() => {
        if (cityZip && typeof window !== 'undefined' && window.caiEventBus !== undefined) {
            // call the elot module again to return updated inventory when zipcode is changed
            window.caiEventBus.on('elot:get-app-data', (callback) => {
                callback({
                    pageName,
                    pageId: pageName,
                    zip: cityZip,
                });
            });
        }
    }, [cityZip, pageName]);

    const getUpdatedDataIsland = useCallback((owner) => {
        const state = store.getState();
        const { brand, currentPageName } = state;

        return getDataIsland({
            data: {
                brand,
            },
            pageName: currentPageName,
            query: {
                dma: {
                    code: _get(owner, 'location.address.zip', ''),
                },
            },
        });
    }, [store]);

    const getScrollValue = useCallback(() => {
        let scrollValue = 0;
        if (scrollPercent > 24 && scrollPercent < 50) {
            scrollValue = 25;
            if (scrollRange25 === '') setScrollRange25(true);
        } else if (scrollPercent > 49 && scrollPercent < 75) {
            scrollValue = 50;
            if (scrollRange50 === '') setScrollRange50(true);
        } else if (scrollPercent > 74 && scrollPercent < 100) {
            scrollValue = 75;
            if (scrollRange75 === '') setScrollRange75(true);
        } else if (scrollPercent === 100) {
            scrollValue = 100;
        }
        return scrollValue;
    }, [scrollPercent, scrollRange25, scrollRange50, scrollRange75, setScrollRange25, setScrollRange50, setScrollRange75]);

    const setScrollRange = useCallback(() => {
        if (scrollRange25) setScrollRange25(false);
        if (scrollRange50) setScrollRange50(false);
        if (scrollRange75) setScrollRange75(false);
    }, [scrollRange25, scrollRange50, scrollRange75, setScrollRange25, setScrollRange50, setScrollRange75]);

    const getOwnerName = useCallback((owner) => (owner?.franchiseName || owner?.name), []);
    const getOwnerId = useCallback((owner) => (owner?.dealerId || owner?.id), []);

    const handleInViewImpression = useCallback((owner, paginatedListingIndex, cmp, index) => {
        const { id, isPayingDealer } = owner;

        sendImpressions({
            data: {
                selectedItemIds: [owner.id],
                paginatedListingIndex,
                cmp,
                owner,
                pixallData: {
                    eventSource: 'dealership',
                    hasListingType: false,
                    label: 'c_lstg',
                    filterValues: {
                        zip: filterValues.zip,
                    },
                },
            },
            name: 'inventoryImpressions',
        });

        if (isPayingDealer) {
            sendImpressions({
                name: 'serviceAppointmentCTAEcomImpression',
                data: {
                    ownerId: id, listingPosition: index + 1,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues.zip]);

    useEffect(() => {
        if (getScrollValue() > 0 && (getScrollValue() === 100 || scrollRange25 || scrollRange50 || scrollRange75)) {
            setScrollRange(scrollRange25, scrollRange50, scrollRange75, setScrollRange25, setScrollRange50, setScrollRange75);
            sendImpressions({
                data: {
                    scroll: getScrollValue(scrollPercent),
                },
                name: 'inventoryScrollImpressions',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getScrollValue]);

    const handleClearSelectionsClick = async () => {
        actions.clearFilters();

        // Update the global data reducers
        actions.rebuildFindADealerPage(router.push);
    };

    const handleViewCarsClick = useCallback((event, owner = {}) => {
        event?.preventDefault();
        event?.stopPropagation();
        if (!hasDocumentSelection()) {
            sendClick('dealerClick', event, { ownerId: getOwnerId(owner) });
            sendClick('inventoryClick', event, {
                par: 'dealer',
                vehicle: { ownerId: getOwnerId(owner) },
            });
            optimizelyTrack('fyd_srl_listing_click');

            const { id, href, isPayingDealer } = owner;
            const ownerUrl = createDdpLink(isKbb, href, isPayingDealer, ddpClickType.VIEW_CAR_CLICK);

            actions.setRetainOwner({ id, name: getOwnerName(owner) });
            actions.setSelectedOwner(id);
            actions.setCurrentPage(pageNames.DEALER_DETAILS);
            actions.setBirf({ dataIsland: getUpdatedDataIsland(owner) });
            router.push(ownerUrl);
        }
    }, [actions, isKbb, router, getUpdatedDataIsland, getOwnerId, getOwnerName, optimizelyTrack]);

    const handleScheduleClick = useCallback((event, href, owner, index) => {
        event?.preventDefault();
        event?.stopPropagation();
        if (!hasDocumentSelection()) {
            const { id } = owner;
            sendClick('serviceAppointmentSubmit', event, { ownerId: id });
            sendClick('serviceAppointmentCTAEcomClick', event, { ownerId: id, listingPosition: index + 1 });

            actions.setRetainOwner({ id, name: getOwnerName(owner) });
            actions.setSelectedOwner(id);
            router.push(href);
        }
    }, [actions, getOwnerName, router]);

    const handleGetAPriceQuoteClick = useCallback((event, owner) => {
        event?.preventDefault();
        event?.stopPropagation();
        if (!hasDocumentSelection()) {
            const { id, href, hasDealerDetail, website } = owner;
            // Variable website?.href has the format ?manufacturername=${item.MakeName}&franchisekey=${(item.FranchiseId * 1000) + item.MakeId} for FDPQ
            sendClick('ownerPriceQuoteClick', event, {
                ownerId: getOwnerId(owner),
                hasListingType: false,
                pixallData: {},
            });
            const url = createLinkForGetAPriceQuote(href, hasDealerDetail, website?.href, owner.storefrontWebURL, getOwnerId(owner), isKbb, owner.makeName);
            actions.setRetainOwner({ id, name: getOwnerName(owner) });
            actions.setSelectedOwner(id);
            window.location = url;
        }
    }, [actions, isKbb, getOwnerId, getOwnerName]);

    const handleDealerCardClick = useCallback((event, owner) => {
        event?.preventDefault();
        event?.stopPropagation();
        if (!hasDocumentSelection()) {
            const { id, href, hasDealerDetail, isPayingDealer } = owner;
            if (hasDealerDetail) {
                sendClick('dealerClick', event, { ownerId: getOwnerId(owner) });
                const url = createDdpLink(isKbb, href, isPayingDealer, ddpClickType.DEALER_CARD_CLICK);
                actions.setRetainOwner({ id, name: getOwnerName(owner) });
                actions.setSelectedOwner(id);
                actions.setCurrentPage(pageNames.DEALER_DETAILS);
                actions.setBirf({ dataIsland: getUpdatedDataIsland(owner) });
                router.push(url);
            }
        }
    }, [actions, isKbb, router, getOwnerId, getOwnerName, getUpdatedDataIsland]);

    const handleVisitSiteClick = useCallback((event, owner) => {
        event?.preventDefault();
        event?.stopPropagation();
        if (!hasDocumentSelection()) {
            const { id, storefrontWebURL, dealerId } = owner;
            if (storefrontWebURL && storefrontWebURL.length > 0) {
                sendClick('ownerWebsite', event, { ownerId: id, dealerId });
                const url = storefrontWebURL;
                actions.setRetainOwner({ id, name: getOwnerName(owner) });
                actions.setSelectedOwner(id);
                window.open(url, '_blank').focus();
            }
        }
    }, [actions, getOwnerName]);

    // Intersection Observer for loading more items
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const first = entries[0];
                if (first.isIntersecting && visibleItems < results.length) {
                    // First show 5 more skeletons
                    setVisibleSkeletons((prev) => Math.min(prev + 5, results.length));

                    // Then after a delay, show the actual content
                    setTimeout(() => {
                        setVisibleItems((prev) => Math.min(prev + 5, results.length));
                    }, 500);
                }
            },
            { threshold: 0.1, rootMargin: '100px' }
        );

        if (loadingRef.current) {
            observer.observe(loadingRef.current);
        }

        return () => observer.disconnect();
    }, [visibleItems, results.length, isLoading]);

    const renderResults = () => {
        // Show initial or loading state skeletons
        if (isLoading) {
            return Array(10).fill(null).map((_, index) => (
                <DealerCardPlaceHolder key={`initial-skeleton-${index}`} />
            ));
        }

        // Render visible items and remaining skeletons
        return (
            <>
                {Array(visibleSkeletons).fill(null).map((_, index) => {
                    if (index < visibleItems && results[index]) {
                        let owner = results[index];
                        const ownerWithoutLogo = { ...owner };
                        delete ownerWithoutLogo.logo;
                        const scheduleHref = owner.isPayingDealer ? createDdpScheduleLink(owner.href) : '';
                        let position = 0;

                        const elotDesiredPosition = parseInt(elotPosition, 10);
                        let showElot = false;

                        if (results.length < elotDesiredPosition) {
                            position = results.length - 1;
                        } else {
                            position = elotDesiredPosition - 1;
                        }

                        if (index === position) {
                            showElot = true;
                        }

                        if (isKbb) {
                            owner = {
                                ...owner,
                                name: owner.ownerTitleCTA,
                            };
                        }
                        return (
                            <React.Fragment key={owner.id}>
                                <DealerSchema owner={ownerWithoutLogo} />
                                <DelayedImpressionObserver
                                    onChange={() => handleInViewImpression(
                                        owner,
                                        currentPage,
                                        'dealer',
                                        index
                                    )}
                                >
                                    <DealerCardContainer
                                        isKbb={isKbb}
                                        isMobile={isMobile}
                                        svocId={owner.dealerId}
                                        name={owner.ownerTitleCTA ?? owner.name}
                                        phone={owner.phone?.value ?? null}
                                        hasDealerDetail={owner.hasDealerDetail}
                                        dealerRatingsLink={`${kbbRatingBadgeUrl}&svocId=${owner.dealerId}&zipCode=${owner.location.address.zip}&searchZip=${cityZip || owner.location.address.zip}&pageId=${pageName}&pageType=${dealerRatingPageType}&widgetId=1&debug=false`}
                                        addressDetails={{ fullAddressText: `${owner.location.address.address1}, ${owner.location.address.city} ${owner.location.address.state}, ${owner.location.address.zip}`, trimmedAddress: `${owner.location.address.address1}` }}
                                        distanceFromSearch={owner.distanceFromSearch}
                                        primaryPhotoUrl={owner.primaryPhotoUrl}
                                        primaryButtonProps={{ text: 'View Cars', onClick: (event) => handleViewCarsClick(event, owner), className: 'btn', isShown: owner.hasDealerDetail, href: createDdpLink(isKbb, owner.href, owner.isPayingDealer, ddpClickType.VIEW_CAR_CLICK), buttonType: 'primary' }}
                                        secondaryButtonProps={
                                            !isShowVisitSite(owner.storefrontWebURL) && isKbb ? {
                                                text: 'Get a Price Quote', onClick: (event) => handleGetAPriceQuoteClick(event, owner), isShown: owner.dealerId != null || isKbb, buttonType: 'secondary',
                                            } : { text: 'Visit Site', onClick: (event) => handleVisitSiteClick(event, owner), isShown: isShowVisitSite(owner.storefrontWebURL), href: owner?.storefrontWebURL, buttonType: 'secondary' }
                                        }
                                        tertiaryButtonProps={{ text: 'Schedule Service', onClick: (event) => handleScheduleClick(event, scheduleHref, owner, index), isShown: scheduleHref.length > 0, buttonType: 'tertiary' }}
                                        handleDealerCardClick={(event) => handleDealerCardClick(event, owner)}
                                        isShowVisitSite={isShowVisitSite(owner.storefrontWebURL)}
                                    />
                                </DelayedImpressionObserver>

                                {showElot && cityZip && (
                                    <LazyComponent renderOnScroll={false}>
                                        <DealerLot
                                            isKbb={isKbb}
                                            elotLibraryUrl={elotLibraryUrl}
                                        />
                                    </LazyComponent>
                                )}
                            </React.Fragment>
                        );
                    }

                    return <DealerCardPlaceHolder key={`skeleton-${index}`} />;
                })}

                {/* Intersection observer trigger */}
                {visibleItems < results.length && (
                    <div
                        ref={loadingRef}
                        style={{ height: '1px', margin: '100px 0' }}
                    />
                )}
            </>
        );
    };

    return (
        <>
            <div name="dealer-listing-count">
                <DealerListResultCount
                    id="dealerListResultCount"
                    className="text-size-200 text-bold margin-bottom-1"
                >
                    {dealerListCountText}
                </DealerListResultCount>
            </div>

            <div className="dealer-card-listing">
                {redirectExpiredPage && <ExpiredDealerAlert />}
                {hasResults
                    ? renderResults()
                    : (
                        <NoResult
                            isKbb={isKbb}
                            handleClearSelectionsClick={handleClearSelectionsClick}
                        />
                    )}
            </div>
            <div>
                <LazyComponent renderOnScroll={false}>
                    <DealerRatingsOverlay
                        ratingsIframeUrl={ratingsIframeUrl}
                        isRatingsOpen={isRatingsOpen}
                        toggleIsRatingsOpen={toggleIsRatingsOpen}
                    />
                </LazyComponent>
            </div>
        </>
    );
});

function mapStateToProps(state) {
    const dealerResultData = dealerResultsDuck.selectors.getDuckState(state);
    const dealerUrlData = dealerUrlsDuck.selectors.getDuckState(state);
    const results = dealerResultsDuck.selectors.getActiveResults(state).map((id) => ({
        ...state.owners[id],
        href: _get(dealerUrlData, `results.${id}`, ''),
    }));
    const filterValues = dealerFiltersDuck.selectors.getFiltersValues(state);
    const cityZip = _get(userDuck.selectors.getLocation(state), 'zip', '');
    const dealerPaginationData = dealerPaginationDuck.selectors.getDuckState(state);

    return {
        hasResults: results.length > 0,
        isLoading: _get(dealerResultData, 'loading', false),
        results,
        currentPage: _get(dealerPaginationData, 'currentPage', 0),
        numRecordsData: { ...numRecords, value: _get(dealerPaginationData, 'numRecords', 25) },
        redirectExpiredPage: state.redirectPage,
        resultCount: dealerResultsDuck.selectors.getResultsCount(state),
        cityZip,
        pageName: _get(state, 'birf.pageData.page.BIRF.pg', ''),
        filterValues,
    };
}

const mapDispatchToProps = mapDispatchToActionProp({
    setRetainOwner: retainOwnerDuck.creators.setRetainOwner,
    setSelectedOwner: inventoryActiveOwnerDuck.creators.setActive,
    clearFilters: dealerFiltersDuck.creators.clearFilters,
    deselectFilter: dealerFiltersDuck.creators.deselectFilter,
    resetPagination: dealerPaginationDuck.creators.resetPagination,
    setCurrentPage: currentPageNameDuck.creators.set,
    setBirf: birfDuck.creators.setState,
    rebuildFindADealerPage,
});

export default connect(mapStateToProps, mapDispatchToProps)(DealerSearchResultsContainer);
